import React from 'react';
import styles from './home.module.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';
 
const Home = () => {
    
    const { height } = useWindowDimensions();

    return (
        <div className={styles.home}>
            <section className={`${styles.homeSection}`} style={{height: height}}>
                <img className={`${styles.bob} ${styles.poppyLogo}`} src="img/logos/Poppy.png" alt="Remembrance Seal"/>
                <h1>Lest We Forget</h1>                
            </section>
        </div>
    );
};
 
export default Home;

