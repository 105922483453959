import React from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom'; 
import Home from '../src/pages/home/home';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Navigate replace to="/home" />} />
      </Routes>
    </Router>
)}
export default App;
